export const translations = {
    UY: {
      "agenda": "agendá",
      "agrupa": "agrupá",
      "ahorra": "ahorrá",
      "alquila": "alquilá",
      "alquiler": "alquiler",
      "amenities": "amenities",
      "area": "rango de Metraje",
      "asegura": "asegurá",
      "asigna": "asigná",
      "aumenta": "aumentá",
      "brinda": "brindá",
      "contáctanos":"contactános",
      "busca": "buscá",
      "buscas": "buscás",
      "completa": "completá",
      "compra": "comprá",
      "conoce": "conocé",
      "consulta": "consultá",
      "contactar": "consultar",
      "construidos": "edicados",
      "coordina": "coordiná",
      "descarga": "descargá",
      "descubre": "descubrí",
      "hab": "dorm",
      "habitaciones": "dormitorios",
      "habitacion": "dormitorio",
      "edificados": "m² edificados",
      "elije": "elegí",
      "empieza": "empezá",
      "encuentra": "encontrá",
      "eres": "sos",
      "filtra": "filtrá",
      "ganar": "ganá",
      "gastosComunes": "gastos comunes incluidos",
      "guarda": "guardá",
      "garage": "garage",
      "haz": "hacé",
      "ingresa": "ingresá",
      "llama": "llamá",
      "llega": "llegá",
      "llevar": "llevá",
      "mejora": "mejorá",
      "mira": "mirá",
      "mono": "mono",
      "nececitas": "necesitás",
      "nuevo": "a estrenar",
      "obten": "obtené",
      "optimiza": "optimizá",
      "planos": "en pozo",
      "publica": "publicá",
      "puedes": "podés",
      "quieres": "querés",
      "recibe": "recibí",
      "recuerdas": "recordás",
      "registra": "registrá",
      "revisa": "revisá",
      "sabes": "sabés",
      "selecciona": "seleccioná",
      "sigue": "seguí",
      "siguenos": "seguinos",
      "solicita": "solicitá",
      "sube": "subí",
      "supervisa": "supervisá",
      "ti": "vos",
      "tienes": "tenés",
      "tu": "vos",
      "transacción":"tipo de operación",
      "unidades":"unidades"
    },
    CO: {
      "agenda": "agenda",
      "agrupa": "agrupa",
      "ahorra": "ahorra",
      "alquila": "alquila",
      "alquiler": "arriendo",
      "amenities": "características",
      "area": "área",
      "asegura": "asegura",
      "asigna": "asigna",
      "aumenta": "aumenta",      
      "brinda": "brinda",
      "busca": "busca",
      "buscas": "buscas",
      "completa": "completa",
      "compra": "compra",
      "conoce": "conoce",
      "contáctanos":"contáctanos",
      "consulta": "consulta",
      "contactar": "contactar",
      "construidos": "construida",
      "coordina": "coordina",
      "descarga": "descarga",
      "descubre": "descubre",
      "hab" : "hab",
      "habitacion": "habitación",
      "habitaciones": "habitaciones",
      "edificados": "área",
      "elije": "elije",
      "empieza": "empieza",
      "encuentra": "encuentra",
      "eres": "eres",
      "filtra": "filtra",
      "gana": "gana",
      "gastosComunes": "administración incluida",
      "guarda": "guarda",
      "garage": "parqueadero",
      "haz": "haz",
      "ingresa": "ingresa",
      "llama": "llama",
      "llega": "llega",
      "lleva": "lleva",
      "mejora": "mejora",
      "mira": "mira",
      "mono": "abierto",
      "nececitas": "nececitas",
      "nuevo": "nuevos",
      "obten": "obten",
      "optimiza": "optimiza",
      "planos": "sobre planos",
      "publica": "publica",
      "puedes": "puedes",
      "quieres": "quieres",
      "recibe": "recibe",
      "recuerdas": "recuerdas",
      "registra": "registra",
      "revisa": "revisa",
      "sabes": "sabes",
      "selecciona": "selecciona",
      "sigue": "sigue",
      "siguenos": "siguenos",
      "solicita": "solicita",
      "sube": "sube",
      "supervisa": "supervisa",
      "ti": "ti",
      "tienes": "tienes",
      "transacción":"tipo de oferta",
      "tu": "tu",
      "unidades":"tipos"
    },
    ce3: {
      "agenda": "agendá",
      "agrupa": "agrupá",
      "ahorra": "ahorrá",
      "alquila": "alquilá",
      "alquiler": "alquiler",
      "amenities": "amenities",
      "area": "rango de Metraje",
      "asegura": "asegurá",
      "asigna": "asigná",
      "aumenta": "aumentá",
      "brinda": "brindá",
      "contáctanos":"contactános",
      "busca": "buscá",
      "buscas": "buscás",
      "completa": "completá",
      "compra": "comprá",
      "conoce": "conocé",
      "consulta": "consultá",
      "contactar": "consultar",
      "construidos": "edicados",
      "coordina": "coordiná",
      "descarga": "descargá",
      "descubre": "descubrí",
      "hab": "dorm",
      "habitaciones": "dormitorios",
      "habitacion": "dormitorio",
      "edificados": "m² edificados",
      "elije": "elegí",
      "empieza": "empezá",
      "encuentra": "encontrá",
      "eres": "sos",
      "filtra": "filtrá",
      "ganar": "ganá",
      "gastosComunes": "gastos comunes incluidos",
      "guarda": "guardá",
      "garage": "garage",
      "haz": "hacé",
      "ingresa": "ingresá",
      "llama": "llamá",
      "llega": "llegá",
      "llevar": "llevá",
      "mejora": "mejorá",
      "mira": "mirá",
      "mono": "mono",
      "nececitas": "necesitás",
      "nuevo": "a estrenar",
      "obten": "obtené",
      "optimiza": "optimizá",
      "planos": "en pozo",
      "publica": "publicá",
      "puedes": "podés",
      "quieres": "querés",
      "recibe": "recibí",
      "recuerdas": "recordás",
      "registra": "registrá",
      "revisa": "revisá",
      "sabes": "sabés",
      "selecciona": "seleccioná",
      "sigue": "seguí",
      "siguenos": "seguinos",
      "solicita": "solicitá",
      "sube": "subí",
      "supervisa": "supervisá",
      "ti": "vos",
      "tienes": "tenés",
      "tu": "vos",
      "transacción": "tipo de operación",
      "unidades":"unidades"
    },
    // Set translations to avoid wrong texts, the first render always show uy translations
    DEFAULT:{
      "habitaciones": "....",
      "descarga": "descarga",
      "busca": "busca",
      "contáctanos":"contáctanos",
      "completa": "completa",
      "siguenos": "siguenos",
    }
};